import axios from "axios"

const getChecklistsByChecklistId = async (checklistId, companyId, limit, skip, statusId, idToken) => {
  try {
    const isStatusId = !(statusId) ? '' : `&status_id=${statusId}`
    const response = await axios.get(`https://topiadev.topiaapp.com/api/checklist/instance?checklist_id=${checklistId}&company_id=${companyId}&limit=${limit}&skip=${skip}${isStatusId}`,
      { headers: { Authorization: `Bearer ${idToken}` } })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getChecklistsByChecklistId