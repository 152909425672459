import axios from "axios"

const createUpdateAmenity = async (formData, idToken) => {
  try {
    const response = await axios.put('https://topiadev.topiaapp.com/api/place/amenity',
    formData,
    {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      }
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      }
    }
  }
}

export default createUpdateAmenity