import axios from "axios"

const getAllHierarchiesByCompanyId = async (companyId, idToken, parentId) => {
  try {
    const isParentId = parentId ? `&parent_id=${parentId}` : ''
    const response = await axios.get(`https://topiadev.topiaapp.com/api/place/tree?company_id=${companyId}${isParentId}`,
      { headers: { Authorization: `Bearer ${idToken}` } })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAllHierarchiesByCompanyId