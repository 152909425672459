import axios from "axios"

const editEmployeeService = async(data) => {
try {
    const res = await axios.put('https://topiadev.topiaapp.com/api/employee', data)
    return {
        success: true,
        data: res.data,
        status: res.status
    }
} catch (error) {
    const [dbError] = error.response.data.errors
    return {
        success: false,
        error: dbError.msg || error.message
    }
}
}

export default editEmployeeService