import axios from "axios";

const getAllContractTypesService = async(companyId) => {
 try {
    const res = await axios.get(`https://topiadev.topiaapp.com/api/company/contract?company_id=${companyId}`)
    return {
        success: true,
        data: res.data
    }
 } catch (error) {
    return {
        success: false,
        data: error.message
    }
 }
};

export default getAllContractTypesService;