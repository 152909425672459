import axios from "axios"

const getPlacesTreeByCompanyId = async (companyId, idToken) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/place/svg/tree?company_id=${companyId}`, {
     headers: ({
        Authorization: `Bearer ${idToken}`
     })
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getPlacesTreeByCompanyId