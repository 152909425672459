import axios from "axios"

const getResidentialDataTableByUser = async (residentialId) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/residential/full?residential_id=${residentialId}`)

    return {
      success: true,
      ...response,
    }
  } catch (error) {
    return {
      success: false,
      ...error.response,
    }
  }
}

export default getResidentialDataTableByUser
