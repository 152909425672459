// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBvV4wPGrHAHiaMISclcbbmjZzz9Eb39Dk',
    authDomain: 'kerberusapp.firebaseapp.com',
    databaseURL: 'https://kerberusapp.firebaseio.com',
    projectId: 'kerberusapp',
    storageBucket: 'kerberusapp.appspot.com',
    messagingSenderId: '620939967246',
    appId: '1:620939967246:web:56bee3c29d827ae0',
};


const firebaseDevConfig = {
    apiKey: 'AIzaSyClYz9VX-BMNjQA3gqVfEpoOyUnmb33lOw',
    authDomain: 'topiaapp-dev.firebaseapp.com',
    databaseURL: 'https://topiaapp-dev.firebaseio.com',
    projectId: 'topiaapp-dev',
    storageBucket: 'topiaapp-dev.appspot.com',
    messagingSenderId: '802939574384',
    appId: '1:802939574384:web:25ec2fb1ad7b35ace37cb5',
};


// Initialize Firebase
// export const FirebaseApp  = initializeApp(firebaseConfig);
// export const FirebaseAuth = getAuth( FirebaseApp );
// export const FirebaseDB   = getFirestore( FirebaseApp );
// export const FirebaseStorage = getStorage(FirebaseApp);

// Initialize Firebase Dev
export const FirebaseApp  = initializeApp( firebaseDevConfig );
export const FirebaseAuth = getAuth( FirebaseApp );
export const FirebaseDB   = getFirestore( FirebaseApp );
export const FirebaseStorage = getStorage( FirebaseApp );
