import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const updateTypeNewById = async (formData, idToken) => {
  try {
    const response = await axios.put('https://topiadev.topiaapp.com/api/news/update',
      formData,
      { headers: { Authorization: `Bearer ${idToken}` },
      })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default updateTypeNewById