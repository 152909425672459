import axios from "axios"

const getAllTypesOfPersonhood = async (idToken) => {
  try {
    const response = await axios.get('https://topiadev.topiaapp.com/api/place/detail/tax/personhood', {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAllTypesOfPersonhood