import {
  deletePlaceHierarchyAdmin,
  editPlaceHierarchyAdmin,
  getHierarchyAdminSpace,
  getPlacesHierarchyAdmin,
  getPlacesTypesHierarchyAdmin,
  setHierarchyAdminSpace,
  setInitialDataSource,
  setPlacesHierarchyAdmin,
  setTypePlacesHierarchyAdmin,
  createNewPlaceHierarchyAdmin,
} from "./index"
import { verifyResponse } from './../../topia/helpers/verifyResponse';
import { setCurrentParentId } from "./hierarchyAdminSlice";
const URL_DEV = "https://topiadev.topiaapp.com/api";

export const getHierarchyAdminSpaceThunk = async (companyId, idToken) => async (dispatch) => {
  const { data, status, success } = await getHierarchyAdminSpace(URL_DEV, companyId, idToken);
  if (success) {
    dispatch(setHierarchyAdminSpace(data))
    return { success, status };
  }
  return verifyResponse({ dataResponse: data, statusResponse: status });
};

export const getPlacesHierarchyAdminSpaceThunk = async ({ companyId, idToken, parentId, actualLimit = 10, skip = 0, sortOrder, sortOrderBy }) => async (dispatch) => {
  const limit = actualLimit;
  const newSkip = skip
  const { data, status, success } = await getPlacesHierarchyAdmin(URL_DEV, companyId, idToken, parentId, limit, newSkip, sortOrder, sortOrderBy);
  if (success) {
    dispatch(setInitialDataSource)
    dispatch(setPlacesHierarchyAdmin(data))
    return { success, status };
  }
  return verifyResponse({ dataResponse: data, statusResponse: status });
};

export const getTypePlacesHierarchyAdminThunk = (idToken) => async (dispatch) => {
  const { data, status, success } = await getPlacesTypesHierarchyAdmin(URL_DEV, idToken);
  if (success) {
    dispatch(setTypePlacesHierarchyAdmin(data));
    return { success, status };
  }
  return verifyResponse({ dataResponse: data, statusResponse: status });
};

export const deletePlacesHierarchyAdminThunk = async (idToken, placeId) => async (dispatch) => {
  const { data, status, success } = await deletePlaceHierarchyAdmin(URL_DEV, idToken, placeId);
  verifyResponse({ dataResponse: data, statusResponse: status, message: "Se ha eliminado un lugar con exito" });
  if (success) dispatch(setPlacesHierarchyAdmin(data));
  return { data, success, status };
};

export const putPlacesHierarchyAdminThunk = async (idToken, formData) => async (dispatch) => {
  const { data, status, success } = await editPlaceHierarchyAdmin(URL_DEV, idToken, formData);
  verifyResponse({ dataResponse: data, statusResponse: status, message: "Se ha editado un lugar con exito" });
  if (success) dispatch(setPlacesHierarchyAdmin(data));
  return { success, status };
};

export const createPlacesHierarchyAdminThunk = async (idToken, formData) => async (dispatch) => {
  const { data, status, success } = await createNewPlaceHierarchyAdmin(URL_DEV, idToken, formData);
  verifyResponse({ dataResponse: data, statusResponse: status, message: "Se ha creado un lugar con exito" });
  if (success) dispatch(setPlacesHierarchyAdmin(data));
  return { success, status };
};


export const currentParentIdThunk = (payload) => async (dispatch) => {
  const { currentParentId } = payload;
  dispatch(setCurrentParentId(currentParentId));
};

