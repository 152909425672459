import axios from "axios"

const getStatusChecklist = async (idToken) => {
  try {
    const response = await axios.get('https://topiadev.topiaapp.com/api/checklist/instance/status',
    { headers: { Authorization: `Bearer ${idToken}` } })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getStatusChecklist