import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getFullDetailTypeNewById = async (idToken, newId) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/news/detail/full?news_id=${newId}`,
      { headers: { Authorization: `Bearer ${idToken}` },
        timeout: 10000
      })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getFullDetailTypeNewById