import axios from "axios"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const createNewTicketByCheckList = async (formData, idToken) => {
  try {
    const response = await axios.post('https://topiadev.topiaapp.com/api/ticket/create/type/checklist',
      formData,
      {
        headers: { Authorization: `Bearer ${idToken}` },
        timeout: 15000,
      })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default createNewTicketByCheckList