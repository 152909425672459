import axios from "axios"

const getConversationsByProfileId = async ({
  companyId,
  profileId,
  skip = 0,
  limit = '',
  idToken,
}) => {
  try {
    const isSkip = skip ? `&skip=${skip}` : ''
    const isLimit = limit ? `&limit=${limit}` : ''
    const response = await axios.get(`https://topiadev.topiaapp.com/api/conversations?company_id=${companyId}&profile_id=${profileId}${isSkip}${isLimit}`,
      { headers: { Authorization: `Bearer ${idToken}` } }
    )

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getConversationsByProfileId