import axios from "axios"

const getAllHistoryTickets = async ({ placeId, idToken, limit, skip, sortOrder, sortBy, findBy, findValue, dateBegin, dateEnd }) => {
  try {
    const isLimit = limit ? `&limit=${limit}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''
    const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
    const isSortBy = sortBy ? `&sort_by=${sortBy}` : ''
    const isFindValue = findValue ? `&find_value=${findValue}` : ''
    const isDateBegin = dateBegin ? `&date_begin=${dateBegin}` : ''
    const isDateEnd = dateEnd ? `&date_end=${dateEnd}` : ''

    const response = await axios.get(`https://topiadev.topiaapp.com/api/ticket/place?place_id=${placeId}${isLimit}${isSkip}${isSortOrder}${isSortBy}${isFindValue}${isDateBegin}${isDateEnd}`, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAllHistoryTickets