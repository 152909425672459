import axios from "axios";

const getDepartments = async (companyId) => {
  const response = await axios.get(
    `https://topiadev.topiaapp.com/api/company/department?company_id=${companyId}`
  );

  try {
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export default getDepartments;
