import axios from "axios"

const getCategoriesByChecklistId = async (checklistId, idToken) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/checklist/instance/category?checklist_instance_id=${checklistId}`,
    { headers: { Authorization: `Bearer ${idToken}` } })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getCategoriesByChecklistId