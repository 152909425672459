import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const deleteTypeNewById = async (formData, idToken) => {
  try {
    const response = await axios.put('https://topiadev.topiaapp.com/api/news/delete',
      formData,
      { headers: { Authorization: `Bearer, ${idToken}` },
        timeout: 10000
      })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default deleteTypeNewById