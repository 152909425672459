import axios from "axios"

const getChecklistDetail = async (companyId, checklistId, checklistCategoryId, checklistItemReview, limit = 10, skip = 0, idToken) => {
  try {
    const isCategoryId = !(checklistCategoryId) ? '' : `&checklist_instance_category_id=${checklistCategoryId}`
    const isReviewStatusId = !(checklistItemReview) ? '' : `&checklist_instance_item_review_status_id=${checklistItemReview}`
    const response = await axios.get(`https://topiadev.topiaapp.com/api/checklist/instance/detail?company_id=${companyId}&checklist_instance_id=${checklistId}${isCategoryId}${isReviewStatusId}&limit=${limit}&skip=${skip}`, 
    { headers: { Authorization: `Bearer ${idToken}` } })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getChecklistDetail