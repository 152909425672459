import axios from "axios";

const getAllEmployee = async (departmentId, skip, companyId) => {
  try {
    const params = {
      limit: 10,
      skip: skip,
      department_id: departmentId,
      company_id: companyId,
    };

    const response = await axios.get(`https://topiadev.topiaapp.com/api/employee`, { params });

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export default getAllEmployee;
