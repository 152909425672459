import axios from "axios";

const createNewEmployeeService = async(data) => {
   try {
    const res = await axios.post('https://topiadev.topiaapp.com/api/employee', data)

    return {
        success: true,
        data: res.data,
        status: res.status
    }
   } catch (error) {
    return {
        success: false,
        error: error.response
    }
   }
};

export default createNewEmployeeService;