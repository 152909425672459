import {
  ActiveMaintenanceIcon, AdminTasksIcon, AdministrativeTicketsIcon, AnnouncementIcon, BoardDirectorsIcon,
  CollectionManagmentIcon, DefaultMenuIcon, HandoverChecklistIcon, HistoryCalendarIcon, ImportUsers, LibraryIcon,
  ManagmentAmenities, ManagmentBooking, ManagmentEmployeesIcon, ManagmentRolesIcon, ManagmentSpacesIcon,
  MessengerIcon, MonitoringContractorsIcon, NewsIcon, PaymentsReceivedIcon, ProvidersAccessIcon, ReceivedAlertsIcon,
  ResidentialAccessIcon, SalesStatementIcon, SecurityAgentsIcon, StartSettingIcon, SurveillanceShiftsIcon,
  SurveysIcon, UserProfilesIcon, UserTicketsIcon, UsersArrearsIcon,
} from "../assets"

export const menuOptions = {
    modules: {
        title: 'MÓDULOS',
        featureCodes: ['FEAT-281', 'FEAT-279', 'FEAT-277', 'FEAT-351', 'FEAT-383', 'FEAT-379', 'FEAT-305', 'FEAT-375', 'FEAT-413', 'FEAT-400', 'FEAT-428', 'FEAT-405', 'FEAT-289', 'FEAT-290', 'FEAT-8', 'FEAT-254', 'FEAT-304', 'FEAT-451'], //Aqui colocar los principales feature codes para este menú
        items: [
            {
                name: 'ACCESOS',
                type: 'myModules',
                active: true,
                image: ResidentialAccessIcon,
                rolName: 'FEAT-281',
                generalRol: ['residential', 'service'],
                featureCodes: ['FEAT-281'], //A partir de este feature se añadiran los children de este modulo a la consulta antes de entrar a dicho módulo
                url: '/access/residential-access',
            },
            {
                name: 'ADMÓN DE RESERVAS',
                type: 'myModules',
                active: true,
                image: ManagmentBooking,
                rolName: 'FEAT-383',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/bookings',
            },
            {
                name: 'ENCUESTAS',
                type: 'myModules',
                active: true,
                image: SurveysIcon,
                rolName: 'FEAT-379',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/surveys',
            },
            {
                name: 'NOTICIAS Y EVENTOS',
                type: 'myModules',
                active: true,
                image: NewsIcon,
                rolName: 'FEAT-305',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/news',
            },
            {
                name: 'MENSAJERIA',
                type: 'myModules',
                active: true,
                image: MessengerIcon,
                rolName: 'FEAT-375',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/messages',
            },
            {
                name: 'ALERTAS RECIBIDAS',
                type: 'myModules',
                active: true,
                image: ReceivedAlertsIcon,
                rolName: 'FEAT-351',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/alertas',
            },
            {
                name: 'ACCESO A PROVEEDORES',
                type: 'myModules',
                active: true,
                image: ProvidersAccessIcon,
                rolName: 'FEAT-413',
                generalRol: ['mall', 'service'],
                featureCodes: [],
                url: '/access/providers-access',
            },
            {
                name: 'BIBLIOTECA',
                type: 'myModules',
                active: true,
                image: LibraryIcon,
                rolName: 'FEAT-400',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/library',
            },
            {
                name: 'DECLARACIÓN DE VENTAS',
                type: 'myModules',
                active: true,
                image: SalesStatementIcon,
                rolName: 'FEAT-428',
                generalRol: ['mall', 'service'],
                featureCodes: [],
                url: '/settings/sales-statement',
            },
            {
                name: 'MANTENIMIENTO DE ACTIVOS',
                type: 'myModules',
                image: ActiveMaintenanceIcon,
                active: true,
                rolName: 'FEAT-405',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/active-maintenance',
            },
            {
                name: 'TICKETS USUARIO',
                active: true,
                type: 'tickets',
                image: UserTicketsIcon,
                generalRol: ['mall', 'residential', 'service'],
                rolName: 'FEAT-8',
                featureCodes: ['FEAT-260'],
                url: '/tickets/users'
            },
            {
                name: 'TICKETS ADMINISTRATIVOS',
                active: true,
                type: 'tickets',
                image: AdministrativeTicketsIcon,
                generalRol: ['mall', 'residential', 'service'],
                rolName: 'FEAT-254',
                featureCodes: ['FEAT-18', 'FEAT-260'],
                url: '/tickets/administrative'
            },
            {
                name: 'CALENDARIO DE TICKETS',
                active: true,
                type: 'tickets',
                image: HistoryCalendarIcon,
                generalRol: ['mall', 'residential', 'service'],
                rolName: 'FEAT-304',
                featureCodes: ['FEAT-18'],
                url: '/tickets/calendar-tickets'
            },
            {
                name: 'CHECKLISTS',
                type: 'checklist',
                active: true,
                image: HandoverChecklistIcon,
                rolName: 'FEAT-289',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/checklists-admin'
            },
            {
                name: 'CHECKLIST DE ORNATO E IMAGEN',
                type: 'checklist',
                active: true,
                image: HandoverChecklistIcon,
                rolName: 'FEAT-451',
                generalRol: ['mall', 'service'],
                featureCodes: [],
                url: '/admin-check'
            },
            {
                name: 'TAREAS PROGRAMADAS',
                active: true,
                type: 'checklist',
                image: AdminTasksIcon,
                rolName: 'FEAT-290',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/checklist/admin'
            },
            {
                name: 'MONITOR DE CONTRATISTAS',
                active: false,
                type: 'myModules',
                image: MonitoringContractorsIcon,
                rolName: '',
                generalRol: ['residential', 'service'],
                featureCodes: [],
                url: '/access/monitoring-contrators',
            },
            //Solo Tuscania
            {
                name: 'ENVÍO DE CONVOCATORIAS',
                type: 'myModules',
                active: false,
                image: AnnouncementIcon,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/announcements',
            },
            {
                name: 'PAGOS RECIBIDOS',
                type: 'payments',
                active: false,
                image: PaymentsReceivedIcon,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/payments',
            },
            {
                name: 'ADMÓN. DE COBROS',
                type: 'payments',
                active: false,
                image: CollectionManagmentIcon,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/collection-managment',
            },
            {
                name: 'USUARIOS EN MORA',
                type: 'payments',
                active: false,
                image: UsersArrearsIcon,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/users-arrears',
            },
            {
                name: 'JUNTA DIRECTIVA',
                type: 'security',
                image: BoardDirectorsIcon,
                active: false,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/board-directors',
            },
            {
                name: 'AGENTES DE SEGURIDAD',
                type: 'security',
                image: SecurityAgentsIcon,
                active: false,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/security-agents',
            },
            {
                name: 'TURNOS DE VIGILANCIA',
                type: 'security',
                image: SurveillanceShiftsIcon,
                active: false,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/surveillance-shifts',
            },
        ]
    },
    settings: {
        title: 'AJUSTES',
        featureCodes: ['FEAT-387', 'FEAT-279', 'FEAT-284', 'FEAT-433'],
        items: [
            {
                name: 'CONFIGURACIÓN INICIAL',
                image: StartSettingIcon,
                active: false,
                rolName: '',
                generalRol: ['residential',],
                featureCodes: [],
                url: '/settings/initial-settings',
            },
            {
                name: 'ADMÓN. DE ESPACIOS',
                image: ManagmentSpacesIcon,
                active: true,
                rolName: 'FEAT-433',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/managment-spaces',
            },
            {
                name: 'ADMÓN. AMENIDADES',
                active: true,
                image: ManagmentAmenities,
                rolName: 'FEAT-387',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/managment-amenities',
            },
            {
                name: 'IMPORTACIÓN DE USUARIOS',
                image: ImportUsers,
                active: false,
                rolName: '',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/import-users',
            },
            {
                name: 'ADMÓN. DE EMPLEADOS',
                image: ManagmentEmployeesIcon,
                active: true,
                rolName: 'FEAT-279',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/managment-employees',
            },
            {
                name: 'PERFILES DE USUARIO',
                image: UserProfilesIcon,
                active: true,
                rolName: 'FEAT-284',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/users-profiles',
            },
            {
                name: 'ADMÓN. DE ROLES',
                image: ManagmentRolesIcon,
                active: true,
                rolName: '',
                generalRol: ['mall', 'residential', 'service'],
                featureCodes: [],
                url: '/settings/managment-roles',
            },
            // Solo Tuscania           
            {
                name: 'ZONAS',
                image: DefaultMenuIcon,
                active: false,
                rolName: '',
                generalRol: ['residential'],
                featureCodes: [],
                url: '/settings/zones',
            },
        ]
    }
}
