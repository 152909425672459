import axios from "axios"

const getAllRolesCompany = async (companyId) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/company/role?company_id=${companyId}`)

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAllRolesCompany