import axios from "axios";

const getAllPositionsService = async(companyId) => {
try {
    const res = await axios.get(`https://topiadev.topiaapp.com/api/company/positions?company_id=${companyId}`)
    return {
        success: true,
        data: res.data
    }
} catch (error) {
    return {
        success: false,
        data: error.messagge
    }
}
};

export default getAllPositionsService;