import axios from "axios"

const getExcelTemplate = async () => {
  try {
    const response = await axios({
      url: 'https://topiadev.topiaapp.com/api/residential/template',
      method: "GET",
      responseType: "blob"
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a');
    link.href = url
    link.setAttribute('download', 'Perfiles-plantilla.xlsx') //or any other extension
    document.body.appendChild(link)
    link.click()

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response,
    }
  }
}

export default getExcelTemplate