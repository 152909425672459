import { cleanEmployee } from "./detailTicketSlice";
import {
  setTicketById,
  setTicketCategory,
  setEmployee,
  getTicketId,
  updateStatusTicketToChecked,
  verifyResponse,
  cancelTicket,
  getTicketCategory,
  updateCategories,
  getEmployee,
  assignEmployee,
  approveInventory,
  updateInspectionSchedule,
  resolvedTicket,
  updateQuantityInventory,
  updateInspectionSession,
  addNewImage,
  addNewFile,
} from "./index";

const URL_DEV = "https://topiadev.topiaapp.com/api/ticket";

export const getDetailTicketById = (id) => async (dispatch) => {
  const { data, status, success } = await getTicketId(URL_DEV, id);
  verifyResponse(data, status);
  dispatch(setTicketById(data));
  return { success, status };
};

export const reviewTicketThunk = (id) => async (dispatch) => {
  const { data, status, success } = await updateStatusTicketToChecked(
    URL_DEV,
    id
  );
  verifyResponse(data, status, "Ticket revisado correctamente");
  dispatch(setTicketById(data));
  return { success, status };
};

export const cancelTicketThunk = (id, msg) => async (dispatch) => {
  const { data, status, success } = await cancelTicket(URL_DEV, id, msg);
  const message = success && "Ticket cancelado correctamente";
  verifyResponse(data, status, message);
  dispatch(setTicketById(data));
  return { success, status };
};

export const getCategoriesTicketThunk = (companyId) => async (dispatch) => {
  const { data, status, success } = await getTicketCategory(URL_DEV, companyId);
  verifyResponse(data, status);
  dispatch(setTicketCategory(data));
  return { success, status };
};

export const putCategoriesTicketThunk =
  (idTicket, idCategory, idSubcategory) => async (dispatch) => {
    const { data, status, success } = await updateCategories(
      URL_DEV,
      idTicket,
      idCategory,
      idSubcategory
    );
    verifyResponse(data, status);
    if (success) dispatch(setTicketById(data));
    return { success, status };
  };

export const getEmployeeThunk = (companyId) => async (dispatch) => {
  const { data, status, success } = await getEmployee(companyId);
  verifyResponse(data, status);
  dispatch(setEmployee(data));
  return { success, status };
};

export const putEmployeeThunk = (idTicket, employeeId) => async (dispatch) => {
  const { data, status, success } = await assignEmployee(
    URL_DEV,
    idTicket,
    employeeId
  );
  verifyResponse(data, status, "Asignado exitosamente");
  if (success) dispatch(setTicketById(data));
  return { success, status };
};

export const approveInventoryThunk =
  (idTicket, employeeId) => async (dispatch) => {
    const { data, status, success } = await approveInventory(
      URL_DEV,
      idTicket,
      employeeId
    );
    verifyResponse(data, status, "Aprobado exitosamente");
    if (success) dispatch(setTicketById(data));
    return { success, status };
  };

export const updateInspectionScheduleThunk =
  (idTicket, employeeId, idSchedule, date, timeBegin, timeEnd) =>
  async (dispatch) => {
    const { data, status, success } = await updateInspectionSchedule(
      URL_DEV,
      idTicket,
      employeeId,
      idSchedule,
      date,
      timeBegin,
      timeEnd
    );
    verifyResponse(data, status, "Editado exitosamente");
    if (success) dispatch(setTicketById(data));
    return { success, status };
  };

export const resolvedTicketThunk =
  (ticketId, employeeId) => async (dispatch) => {
    const { data, status, success } = await resolvedTicket(
      URL_DEV,
      ticketId,
      employeeId
    );
    verifyResponse(data, status, "Finalizado exitosamente");
    if (success) dispatch(setTicketById(data));
    return { success, status };
  };

export const updateQuantityInventoryThunk =
  (ticketId, employeeId, equipmentInventory, supplyInventory) =>
  async (dispatch) => {
    const { data, status, success } = await updateQuantityInventory(
      URL_DEV,
      ticketId,
      employeeId,
      equipmentInventory,
      supplyInventory
    );
    verifyResponse(data, status, "Inventario editado exitosamente");
    if (success) dispatch(setTicketById(data));
    return { success, status };
  };

export const updateInspectionSessionThunk =
  (ticketId, employeeId, session) => async (dispatch) => {
    const { data, status, success } = await updateInspectionSession(
      URL_DEV,
      ticketId,
      employeeId,
      session
    );
    verifyResponse(data, status, "Inspeccion actualizada exitosamente");
    if (success) dispatch(setTicketById(data));
    return { success, status };
  };
export const addImageDocumentThunk = (file, ticketId) => async (dispatch) => {
  const { data, status, success } = await addNewImage(URL_DEV, file, ticketId);
  verifyResponse(data, status, "Imagen agregada exitosamente");
  if (success) dispatch(setTicketById(data));
  return { success, status };
};

export const addFileDocumentThunk = (file, ticketId) => async (dispatch) => {
  const { data, status, success } = await addNewFile(URL_DEV, file, ticketId);
  verifyResponse(data, status, "Archivo agregada exitosamente");
  if (success) dispatch(setTicketById(data));
  return { success, status };
};
