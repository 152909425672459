import axios from "axios"

const getAllTicketsCategoriesByCompany = async (companyId) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/ticket/category/all?company_id=${companyId}`)

    return {
      success: true,
      ...response
    }
    
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAllTicketsCategoriesByCompany