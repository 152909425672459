import axios from "axios"

const getDetailSpaceByPlaceId = async (placeId, idToken) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/place/detail?place_id=${placeId}`,
      { headers: ({ Authorization: `Bearer ${idToken}` }) })

    return {
      success: true,
      ...response
    }    
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getDetailSpaceByPlaceId