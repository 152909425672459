import axios from "axios";

const getEmployeeInfoService = async(employeeId) => {
    try {
        const res = await axios.get(`https://topiadev.topiaapp.com/api/employee/detail?employee_id=${employeeId}`)
        
        return {
            success: true,
            data: res.data
        }
    } catch (error) {
        const [dbError] = error.response.data.errors


        return {
            success: false,
            error: dbError.msg || error.message
        }
    }
};

export default getEmployeeInfoService;