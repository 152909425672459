import axios from "axios"
import { verifyBodyResponse } from './../../../helpers/verifyBodyResponse';

const getAllNewsType = async({
  idToken,
  companyId,
  dateBegin = '',
  dateEnd = '',
  published,
  active,
  newsTypeId,
  skip = 0,
  limit = 10
}) => {
  try {
    const isSkip = skip ? `&skip=${skip}` : ''
    const isLimit = limit ? `&limit=${limit}` : ''
    const isActive = typeof(active) === 'string' ? '' : `&active=${active}`
    const isDateEnd = dateEnd ? `&date_end=${dateEnd}` : ''
    const isDateBegin = dateBegin ? `&date_begin=${dateBegin}` : ''
    const isNewTypeId = newsTypeId ? `&news_type_id=${newsTypeId}` : ''

    const response = await axios.get(`https://topiadev.topiaapp.com/api/news?company_id=${companyId}${isDateBegin}${isDateEnd}&published=${published}${isActive}${isNewTypeId}${isSkip}${isLimit}`, {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000,
    })
    
    return verifyBodyResponse(response, true)

} catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getAllNewsType