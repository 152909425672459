import axios from "axios"

const getAllMapFilesByPlaceId = async (placeId, limit, skip, idToken) => {
  try {
    const isLimit = limit ? `&limit=${limit}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''
    const response = await axios.get(`https://topiadev.topiaapp.com/api/place/file/map?place_id=${placeId}${isLimit}${isSkip}`, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAllMapFilesByPlaceId