import axios from "axios"

const getResidentialDataByUserLogged = async (residentialId) => {
  try {
    const response = await axios.get(`https://topiadev.topiaapp.com/api/residential/info?residential_id=${residentialId}`)

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getResidentialDataByUserLogged
